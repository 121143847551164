import { RequestConfig } from 'packages/http-client/fetcher';

export type CryptoCode = string;

export interface CryptoBalance {
  crypto_code: CryptoCode;
  crypto_name: string;
  crypto_price: number;
  crypto_amount: number;
  fiat_amount: number;
}

export interface CryptoHoldingsResponse {
  fiat_currency: string;
  total_balance: number;
  crypto_balances: CryptoBalance[];
}

/**
 * https://confluence.corp.netspend.com/display/~apunia/LLD+Crypto+Initiative#LLDCryptoInitiative-GETCryptoAccountHoldings
 */
export const createGetCryptoAccountHoldingsRequest = (): RequestConfig<
  CryptoHoldingsResponse
> => ({
  url: '/v1/crypto/crypto_balance',
  method: 'GET'
});
