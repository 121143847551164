/**
 * https://bitbucket.hq.netspend.com/projects/APP/repos/webapi/browse/src/main/java/com/netspend/webapi/api/cards/dto/CardType.java
 */
export enum CardType {
  PERSONALIZED = 'personalized',
  TEMPORARY = 'temporary',
  VIRTUAL = 'virtual',
  GIFT = 'gift',
  TRAVEL = 'travel',
  UNKNOWN = 'unknown'
}

/**
 * https://bitbucket.hq.netspend.com/projects/APP/repos/webapi/browse/src/main/java/com/netspend/webapi/api/cards/dto/CardBrand.java
 */
export interface CardBrand {
  display_priority: number;
  custom: boolean;
  price: number;
  name: string;
  description: string;
  type: CardType;
  issuer_number: string;
}

export const compareDisplayPriorityDescending = (
  a: CardBrand,
  b: CardBrand
) => {
  if (b.display_priority !== a.display_priority) {
    return b.display_priority - a.display_priority;
  }

  // fallback to sorting alphabetically when display priorities are equal
  return a.name > b.name ? 1 : -1;
};
