import {
  createJsonBodyRequestConfig,
  RequestConfig
} from 'packages/http-client/fetcher';

export interface AlertSubscriptionRequestBody {
  address: string | null;
  address_type?: string;
  alert_enabled?: boolean;
  device_os?: 'IOS' | 'ANDROID';
  per_transaction?: boolean;
  on_demand?: boolean;
  balance_weekly?: boolean;
  budgets?: boolean;
  budget_over?: boolean;
  budget_approaching?: boolean;
  deposit_only?: boolean;
  on_decline?: boolean;
  holds?: boolean;
  payback_rewards?: boolean;
  payback_points?: boolean;
  marketing_promotions?: boolean;
}

export interface Subscription {
  address: string;
  address_type: string;
  address_status: string;
  alert_types: string[];
  alert_enabled: boolean;
}

export interface AlertSubscription {
  subscriptions: Subscription[];
}

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV2AlertSubscriptions
 */
export const createGetAnytimeAlertsRequest = (): RequestConfig<
  AlertSubscription
> => ({
  url: '/v2/alerts',
  method: 'GET'
});

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV2AlertSubscriptionCreate
 */
export const createSubscribtionAnytimeAlertsRequest = ({
  payload
}: {
  payload: AlertSubscriptionRequestBody;
}): RequestConfig<unknown> =>
  createJsonBodyRequestConfig({
    url: '/v2/alerts',
    method: 'POST',
    mutating: true,
    json: payload
  });

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV2AlertSubscriptionEdit
 */
export const createUpdateSubscriptionAnytimeAlertsRequest = ({
  payload
}: {
  payload: AlertSubscriptionRequestBody;
}): RequestConfig<unknown> =>
  createJsonBodyRequestConfig({
    url: '/v2/alerts',
    method: 'PUT',
    mutating: true,
    json: payload
  });
