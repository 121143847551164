import {
  createFormBodyRequestConfig,
  RequestConfig
} from 'packages/http-client/fetcher';
import { authBlocksRequest, WithAccessToken } from '../utils';

export interface OobaAnswerChallengeResponse extends WithAccessToken {
  response: boolean;
  device_remembered: boolean;
}

export interface OobaAnswerChallengeRequest {
  code_attempt: string;
  remember_device: boolean;
  fingerprint: string;
}

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1OobaServiceAnswerChallenge
 */
export const createOobaAnswerChallengeRequest = async ({
  code_attempt,
  remember_device,
  fingerprint
}: OobaAnswerChallengeRequest): Promise<
  RequestConfig<OobaAnswerChallengeResponse>
> =>
  authBlocksRequest(
    createFormBodyRequestConfig({
      url: '/v1/ooba/answerchallenge',
      method: 'POST',
      mutating: true,
      json: {
        code_attempt,
        remember_device,
        fingerprint: await fingerprint
      }
    })
  );

export enum OobaDeliveryTypes {
  SMS = 1,
  EMAIL = 2
}

export interface OobaAddress {
  address: string; // this is an id
  masked_address: string;
  delivery_type: OobaDeliveryTypes;
}
export interface OobaGetDeliveryAddressesResponse extends WithAccessToken {
  ooba_addresses: OobaAddress[];
  available_ooba_skips: number;
  can_update_contact_info: boolean;
}

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1OobaServiceGetDeliveryAddresses
 */
export const createOobaGetDeliveryAddressesRequest = (): // access_token: string
RequestConfig<OobaGetDeliveryAddressesResponse> =>
  authBlocksRequest({
    url: '/v1/ooba/deliveryaddresses',
    method: 'GET'
  });

export interface OobaDeliverCodeResponse extends WithAccessToken {
  response: boolean;
  can_remember_device: boolean;
  resend_denied?: boolean;
}

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1OobaServiceDeliverOobaCode
 */
export const createOobaDeliverCodeRequest = ({
  delivery_address
}: {
  delivery_address: string; // OobaAddress id
}): RequestConfig<OobaDeliverCodeResponse> =>
  authBlocksRequest(
    createFormBodyRequestConfig({
      url: '/v1/ooba/delivercode',
      method: 'POST',
      mutating: true,
      json: {
        delivery_address
      }
    })
  );
export enum OobaErrors {
  CODE_EXPIRED = 'ooba.code_expired',
  CODE_MISMATCH = 'ooba.code_mismatch'
}
