/**
 * Related to a badly named webapi enum...
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1AccountType
 */
export enum ProgramType {
  GPR = 'gpr',
  GIFT = 'gift',
  TRAVEL = 'travel',
  PAYCARD = 'paycard',
  SAVINGS = 'savings',
  BUSINESS = 'business',
  AWARD = 'award',
  BUSINESS_SUBACCOUNT = 'business_subaccount',
  GPR_SUB_ACCOUNT = 'gpr_sub_account',
  CORP_DISBURSEMENT = 'corp_disbursement',
  CORP_EXPENSE = 'corp_expense',
  CONSUMER_DDA = 'consumer_dda',
  BAW_DDA = 'baw_dda',
  PAYROLL_SETTLEMENT = 'payroll_settlement',
  FEE_SETTLEMENT = 'fee_settlement',
  PAYMENT_INFO = 'payment_info',
  GENETIC_GLOBAL = 'genetic_global',
  HUB_USER = 'hub_user',
  UNKNOWN = 'unknown'
}
