import { Brand } from '../branding';

export enum AccountTypes {
  DEBIT = 'debit',
  DDA = 'dda',
  GPR_SUB = 'gpr_sub',
  SAVINGS = 'savings',
  LOYALTY_POINTS = 'loyalty_points',
  BUSINESS_SUB = 'business_sub',
  CREDIT = 'secure_credit_builder'
}

export interface Account {
  id: string;
  posted_balance: number;
  available_balance: number;
  type: AccountTypes;
  name: string;
  status: string;
  creation_date: string;
  closed_date: string;
  user_id?: string;
  linked_secure_deposit_account_id?: string;
  linked_account_id?: string;
  total_credit_limit?: number;
  available_credit_limit?: number;
  payment_due_amount?: number;
  payment_due_date?: string;
  credit_score?: number;
  outstanding_balance?: number;
  last_payment_amount?: number;
}

export interface BusinessProfile {
  name: string;
  verification_level: string;
  category: string;
  type: string;
  submitted_date: string;
  verified_date: string;
}

export interface Program {
  brand: Brand;
  association?: string;
  bank: string;
  type: string;
}
