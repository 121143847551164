import { RequestConfig } from 'packages/http-client/fetcher';

export interface LoyaltyProgramCashbackData {
  currently_earning: {
    balance: number;
    redemption_period: {
      expires_at: string;
      redeem_at: string;
    };
  };
}
export interface QualifyingPurchaseResponse {
  expiresAt: string;
  redeemAt: string;
}

export const createQualifyingPurchaseTransactions = (
  lastEntry: undefined | string | null
): RequestConfig<QualifyingPurchaseResponse> => ({
  url: `/v1/account/loyalty/cashback/transactions${
    lastEntry && lastEntry !== 'undefined' ? `?starting_after=${lastEntry}` : ''
  }`,
  method: 'GET'
});
