import { RequestConfig } from 'packages/http-client/fetcher';

export type RewardsType = 'cashback' | 'pos' | 'merchant_offers' | 'points';
export type Periods =
  | 'days'
  | 'current_month'
  | 'current_quarter'
  | 'current_year'
  | 'previous_year'
  | 'lifetime';

export interface RewardsResponse {
  rewards_type: RewardsType;
  period: Periods;
  period_value: string;
  earned: number;
  redeemed: number;
}

export interface RewardImage {
  context: 'UNFUNDED' | 'FUNDED' | 'FUNDED_NO_REWARD';
  description: string;
  path: string;
}
export interface RewardContentResponse {
  rewards_type: RewardsType;
  rewards_program: string;
  images: RewardImage[];
}

export interface RewardsMilestoneResponse {
  period: Periods;
  period_value: number;
  type: 'amount' | 'points';
  type_value: number;
}

/**
 * https://confluence.corp.netspend.com/display/proddev/Design+Doc+-+Rewards+Communication+Enhancements#DesignDoc-RewardsCommunicationEnhancements-GetRewards
 */
export const createRewardRequest = (
  rewardType: RewardsType,
  period: Periods,
  periodValue?: string
): RequestConfig<RewardsResponse> => ({
  url: `/v1/rewards?rewards_type=${rewardType}&period=${period}${
    periodValue ? `&period_value=${periodValue}` : ''
  }`,
  method: 'GET'
});

/**
 * https://confluence.corp.netspend.com/display/proddev/Design+Doc+-+Rewards+Communication+Enhancements#DesignDoc-RewardsCommunicationEnhancements-GetRewards
 */
export const createGetRewardContentRequest = (
  rewardType: RewardsType
): RequestConfig<RewardContentResponse> => ({
  url: `/v1/rewards/content?rewards_type=${rewardType}`,
  method: 'GET'
});

/**
 * https://confluence.corp.netspend.com/display/proddev/Design+Doc+-+Rewards+Communication+Enhancements#DesignDoc-RewardsCommunicationEnhancements-GetRewards
 */
export const createGetRewardsMilestoneRequest = (): RequestConfig<
  RewardsMilestoneResponse
> => ({
  url: `/v1/rewards/milestone`,
  method: 'GET'
});
