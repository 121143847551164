import {
  createJsonBodyRequestConfig,
  RequestConfig
} from 'packages/http-client/fetcher';
import { authBlocksRequest } from '../utils';

export * from './card-brand';

export interface CardImage {
  width: string;
  density: string;
  url_template: string;
  association?: string;
  banks?: string;
}

export interface Card {
  association: string;
  cardOrderDate: string;
  id: string;
  issuer_number: string;
  obscured_pan: string;
  pan_last_4: string;
  status: string;
  activation_date?: Date;
  card_owner_id?: string;
  category?: string;
  embossedName?: string;
  expiration_date?: Date;
  images?: {
    [key: string]: CardImage;
  };
  inactivation_date?: Date;
  lost_date?: Date;
  stolen_date?: Date;
  type?: string;
  shipped_date?: string;
}

export interface Cards {
  cards: Card[];
}
export interface PauseActivityRefsRequest {
  control: string;
  control_value: string;
  pseudo_pan: string;
}

export const createCardsRequest = (): RequestConfig<Cards> =>
  authBlocksRequest({
    method: 'GET',
    url: '/v1/cards'
  });

/**
 * https://confluence.corp.netspend.com/display/proddev/WebapiV2restrictions
 */

export const pauseCardActivityRequest = ({
  control,
  control_value,
  pseudo_pan
}: {
  control: string;
  control_value: string;
  pseudo_pan: string;
}): RequestConfig<PauseActivityRefsRequest> =>
  createJsonBodyRequestConfig({
    url: '/v2/cards/restrictions',
    method: 'POST',
    mutating: true,
    json: {
      control,
      control_value,
      pseudo_pan
    }
  });
