import { createFormBodyRequestConfig } from 'packages/http-client/fetcher';
import { RequestConfig } from 'packages/http-client/react';
import { filterUndefinedProperties } from 'apps/acp/packages/filter-undefined-properties';

export interface CardOwnerAddress {
  city: string;
  line1: string;
  line2: string;
  state: string;
  zip: string;
  zip4: string;
}
export interface PrimaryCardOwner {
  id: string;
  cip: string;
  dob: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  primary: boolean;
  residential_address: CardOwnerAddress;
  ship_to_address: CardOwnerAddress;
}

export enum EditCardOwnerErrors {
  FIRST_NAME_FORMAT = 'first_name.format',
  LAST_NAME_FORMAT = 'last_name.format',
  ADDRESS_CANADIAN = 'address.canadian',
  ADDRESS_GENERAL_DELIVERY = 'address.general_delivery',
  ADDRESS_INVALID = 'address.invalid',
  ADDRESS_MATCH_NOT_FOUND = 'address.match_not_found',
  ADDRESS_MULTIPLE_MATCHES = 'address.multiple_matches',
  ADDRESS_OUT_OF_RANGE = 'address.out_of_range',
  ADDRESS_PO_BOX = 'address.po_box',
  ADDRESS_STREET_UNKNOWN = 'address.street_unknown',
  ADDRESS_PRIVATE_MAILBOX = 'address.private_mailbox',
  ADDRESS_ZIP_INVALID = 'address.zip_invalid',
  ZIP_FORMAT = 'address.zip.format',
  DOB_FORMAT = 'dob.format',
  SSN_FORMAT = 'ssn.format',
  DOB_ALREADY_EXISTS = 'dob.already_exists',
  DOB_ADDITIONAL_CARD_OWNER_TOO_YOUNG = 'dob.additional_card_owner_too_young',
  DOB_PRIMARY_CARD_OWNER_TOO_YOUNG = 'dob.primary_card_owner_too_young',
  DOB_PRIMARY_CARD_OWNER_MINOR = 'dob.primary_card_owner_minor',
  DATA_INVALID = 'data.invalid',
  SSN_ALREADY_EXISTS = 'ssn.already_exists',
  EMAIL_FORMAT = 'email.format',
  EMAIL_LENGTH = 'email.length',
  PHONE_FORMAT = 'phone.format',
  ADDRESS_LINE1_MISSING = 'address_line1.missing',
  ADDRESS_LINE1_FORMAT = 'address_line1.format',
  ADDRESS_LINE1_RANGE = 'address_line1.range',
  SHIP_ADDRESS_LINE1_MISSING = 'ship_to_address_line1.missing',
  SHIP_ADDRESS_LINE1_FORMAT = 'ship_to_address_line1.format',
  SHIP_ADDRESS_LINE1_RANGE = 'ship_to_address_line1.range',
  SHIP_ADDRESS_CANADIAN = 'ship_to_address.canadian',
  SHIP_ADDRESS_INVALID = 'ship_to_address.invalid',
  SHIP_ADDRESS_MATCH_NOT_FOUND = 'ship_to_address.match_not_found',
  SHIP_ADDRESS_MULTIPLE_MATCHES = 'ship_to_address.multiple_matches',
  SHIP_ADDRESS_OUT_OF_RANGE = 'ship_to_address.out_of_range',
  SHIP_ADDRESS_PRIVATE_MAILBOX = 'ship_to_address.private_mailbox',
  SHIP_ADDRESS_STREET_UNKNOWN = 'ship_to_address.street_unknown',
  SHIP_ADDRESS_ZIP_INVALID = 'ship_to_address.zip_invalid',
  SHIP_ZIP_FORMAT = 'ship_to_zip.format'
}

export interface EditCardOwnerResponse {
  alternate_phone_update: boolean;
  dob_updated: boolean;
  email_updated: boolean;
  govt_id_updated: boolean;
  name_updated: boolean;
  phone_updated: boolean;
  residential_address_updated: boolean;
  ship_to_address_updated: boolean;
}

export interface EditCardOwnerRequestParameters {
  cardOwnerId: string;
  first_name?: string;
  last_name?: string;
  address_line1?: string;
  address_line2?: string;
  zip?: string;
  dob?: string;
  ssn?: string;
  email?: string;
  phone?: string;
  ship_to_address_line1?: string;
  ship_to_address_line2?: string;
  ship_to_zip?: string;
}

export interface GetCardOwnersResponse {
  owners: PrimaryCardOwner[];
}

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1CardOwnerList
 */
export const createGetCardOwnersRequest = (): RequestConfig<
  GetCardOwnersResponse
> => ({
  url: `/v1/cardowners`,
  method: 'GET'
});

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1CardOwnerIndividual
 */
export const createGetCardOwnerRequest = (
  cardOwnerId: string
): RequestConfig<PrimaryCardOwner> => ({
  url: `/v1/cardowners/${cardOwnerId}`,
  method: 'GET'
});

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1CardOwnerEdit
 */
export const createEditCardOwnerRequest = ({
  cardOwnerId,
  first_name,
  last_name,
  address_line1,
  address_line2,
  zip,
  dob: formattedDob,
  ssn,
  email,
  phone,
  ship_to_address_line1,
  ship_to_address_line2,
  ship_to_zip
}: EditCardOwnerRequestParameters): RequestConfig<EditCardOwnerResponse> => {
  return createFormBodyRequestConfig({
    url: `/v1/cardowners/${cardOwnerId}`,
    method: 'PUT',
    mutating: true,
    json: filterUndefinedProperties({
      first_name,
      last_name,
      address_line1,
      address_line2,
      zip,
      dob: formattedDob,
      ssn,
      email,
      phone,
      ship_to_address_line1,
      ship_to_address_line2,
      ship_to_zip
    })
  });
};
