export enum PointsRedemptionTypes {
  CASH = 'CASH',
  EXTERNAL = 'EXTERNAL'
}

export interface PointsRedemptionOption {
  id: string;
  category: PointsRedemptionTypes;
  cash_value: number;
  points_required: number;
  redeemable: boolean;
  description: string;
}
