import { RequestConfig } from 'packages/http-client/fetcher';

export interface OverdraftDetails {
  od_active: boolean;
  od_free_limit: number;
  od_fee_amount: number;
  od_fee_grace_hours: number;
  monthly_fee_quantity_cap: number;
  ongoing_eligibility_amount: number;
  ongoing_eligibility_days: number;
}

export const createProductUpgradeOverdraftDetailsRequest = (): RequestConfig<
  OverdraftDetails
> => ({
  url: '/v1/productupgrade/overdraftdetails',
  method: 'GET'
});

/**
 * https://confluence.corp.netspend.com/display/proddev/WebapiAccountOverdraftSummary
 */
export const createGetOverdraftDetailsRequest = (): RequestConfig<
  OverdraftDetails
> => ({
  url: '/v1/overdraft/summary',
  method: 'GET'
});
