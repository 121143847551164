import { RequestConfig } from 'packages/http-client/fetcher';
import { Account, BusinessProfile, Notification, Program, User } from './types';

export interface Profile {
  program: Program;
  user: User;
  accounts: Account[];
  features: {
    [featureName: string]: boolean;
  };
  business?: BusinessProfile;
  aggregate_available_balance: number;
  notifications: Notification[];
}

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1UserProfile
 */
export const createGetProfileRequest = (): RequestConfig<Profile> => ({
  method: 'GET',
  url: '/v1/me'
});
