import {
  RequestConfig,
  createJsonBodyRequestConfig
} from 'packages/http-client/fetcher';
export interface Location {
  id: string;
  suppressible: boolean;
  data?: {
    body: string;
    body_key: string;
    body_with_html_tags?: string;
    body_with_html_tags_key?: string;
    body_extended?: string;
    body_extended_key?: string;
    body_disclosure?: string;
    body_disclosure_key?: string;
    headline: string;
    headline_key: string;
    cta_label: string;
    cta_label_key: string;
    suppress_label?: string;
    suppress_label_key?: string;
    cta_action: {
      type: string;
      data: string | { href: string; target: string };
      url?: string;
    };
    type: string;
  };
}

// https://confluence.corp.netspend.com/display/dev/ServicingApiAddressObject
interface Address {
  name?: string;
  contact_name?: string;
  line_1?: string;
  line1: string;
  line2?: string;
  city: string;
  state: string;
  zip?: number;
  zip4?: number;
  phone?: number;
  coordinates: Coordinates;
}

// https://confluence.corp.netspend.com/display/dev/ServicingApiCoordinatesObject
export interface Coordinates {
  lat: number;
  lng: number;
}

// https://confluence.corp.netspend.com/display/dev/ServicingApiStoreObject
export interface CardStore {
  distributor: string;
  id: string;
  name: string;
  address: Address;
  reload_fee?: number;
  phone: number;
}

interface PhoneNumber {
  number: string;
  type: string;
}
export interface BarcodeStore {
  id: string;
  merchant_id: string;
  merchant_name: string;
  contact_name?: string;
  phone_numbers?: PhoneNumber[];
  distance_in_miles?: string;
  address: Address;
  reload_fee?: {
    currency: string;
    value?: number;
  };
  vendor: string;
}

export interface BarcodeStoreInitial {
  id: string;
  merchant_id: string;
  merchant_name: string;
  contact_name?: string;
  phone_numbers?: PhoneNumber[];
  distance_in_miles?: string;
  address: {
    line_1: string;
    city: string;
    state: string;
    country: string;
    zip?: string;
    coordinates?: { lat: string; lng: string };
    latitude: string;
    longitude: string;
  };
  reload_fee?: {
    currency: string;
    value?: number;
  };
  vendor: string;
}
export interface StoresResponse {
  stores: CardStore[] | BarcodeStore[] | BarcodeStoreInitial[];
}

interface CardStoresReq {
  lat: number;
  lng: number;
  distributors?: string;
  originating_distributor?: string;
  max?: string;
}

interface BarcodeStoresReq {
  zip?: number;
  latitude: number;
  longitude: number;
  city?: string;
  state?: string;
}

interface RequestParams {
  body: CardStoresReq | BarcodeStoresReq;
}

export interface GenerateBarcodeReqParams {
  vendor: string;
  merchant_id?: string;
  merchant_name?: string;
}

export interface CancelBarcodeReqParams {
  status: string;
  barcode_transaction_mapping_id: string;
}

export interface BarcodeDataResponse {
  barcode_transaction_mapping_id: string;
  barcode_identifier: string;
  expiration_time: string;
  merchant_font_specification?: string;
  status: string;
  vendor: string;
  merchant_id?: string;
  merchant_name?: string;
  reload_fee?: {
    currency: string;
    value?: string;
  };
}

export interface WidgetDataResponse {
  widgets: [
    {
      id: string;
      suppressible: boolean;
    }
  ];
}

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1ListStores
 */
export const createGetStoreLocationsRequest = (
  request: RequestParams
): RequestConfig<StoresResponse> => {
  const {
    lat,
    lng,
    max,
    originating_distributor
  } = request.body as CardStoresReq;

  return {
    url: `/v1/stores?lat=${lat.toString()}&lng=${lng.toString()}&max=${max}&originating_distributor=${originating_distributor}`,
    method: 'GET',
    skipAuth: true
  };
};

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1ListBarcodeStores
 */
export const createGetBarcodeStoresRequest = (
  request: RequestParams
): RequestConfig<StoresResponse> => {
  const payload = request.body as BarcodeStoresReq;

  return createJsonBodyRequestConfig({
    url: '/v1/barcode/stores',
    method: 'POST',
    mutating: false,
    json: payload
  });
};

/**
 * https://confluence.corp.netspend.com/display/proddev/WebAPIV1GenerateBarcode
 */
export const createGenerateBarcodeRequest = (
  request: GenerateBarcodeReqParams
): RequestConfig<BarcodeDataResponse> => {
  const payload = request as GenerateBarcodeReqParams;

  return createJsonBodyRequestConfig({
    url: '/v1/barcode/generate',
    method: 'POST',
    mutating: true,
    opts: { beacon: true },
    json: payload
  });
};

/**
 * https://confluence.corp.netspend.com/display/proddev/WebAPIV1GetBarcode
 */
export const createGetBarcodeDetails = (
  mutate?: boolean
): RequestConfig<BarcodeDataResponse> =>
  mutate
    ? {
        url: '/v1/barcode',
        method: 'GET',
        mutating: true,
        opts: { beacon: true }
      }
    : { url: '/v1/barcode', method: 'GET' };

/**
 * https://confluence.corp.netspend.com/display/proddev/WebAPIV1UpdateBarcode
 */
export const getCancelBarcode = (
  request: CancelBarcodeReqParams
): RequestConfig<StoresResponse> => {
  const {
    barcode_transaction_mapping_id,
    status
  } = request as CancelBarcodeReqParams;

  return createJsonBodyRequestConfig({
    url: '/v1/barcode/' + barcode_transaction_mapping_id,
    method: 'PATCH',
    mutating: false,
    json: {
      status: status
    }
  });
};

/**
 * https://confluence.corp.netspend.com/display/proddev/Draft%3A+Interstitials
 */
export const createGetWidget = (): RequestConfig<WidgetDataResponse> => {
  return {
    url: '/v1/widgets?insertion_point=oac_location_finder',
    method: 'GET',
    mutating: true,
    opts: { beacon: true }
  };
};

/**
 * https://confluence.corp.netspend.com/display/proddev/Draft%3A+Interstitials
 */
export const createDeleteWidget = (
  id: string
): RequestConfig<WidgetDataResponse> => {
  return {
    url: '/v1/interstitials/' + id,
    method: 'DELETE'
  };
};
