import { RequestConfig } from 'packages/http-client/fetcher';

export interface RAF {
  acquisition_aid: string;
  load_amount: number;
  referree_payout: number;
  referrer_payout: number;
}

export const createReferAFriendRequest = (): RequestConfig<RAF> => {
  return {
    method: 'GET',
    url: `/v2/raf`
  };
};
