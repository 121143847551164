import {
  RequestConfig,
  createFormBodyRequestConfig
} from 'packages/http-client/fetcher';
import { WithAccessToken } from './utils';

/**
 * @see webapi/src/main/java/com/netspend/webapi/api/auth/dto/AuthorizationBlock.java
 */
export enum AuthorizationBlocks {
  ACTIVATE_ACCOUNT = 'activate_account',
  OOBA_REQUIRED = 'ooba_required',
  CHANGE_PASSWORD = 'change_password',
  ANSWER_QUESTION = 'answer_questions',
  SET_SECURITY_QUESTION = 'set_security_question',
  SUSPICIOUS_TRANSACTION = 'suspicious_transaction',
  DORMANT_ACCOUNT_BLOCK = 'dormant',
  PAYCARD_SELF_ENROLLMENT = 'paycard_self_enrollment',
  ID_CHECK_WITHOUT_DOC = 'id_check_without_doc',
  ID_CHECK_WITH_DOC = 'id_check_with_doc'
}

export interface LoginResponse extends WithAccessToken {
  authorization_blocks?: AuthorizationBlocks[];
}
export interface AuthenticationStatusResponse {
  cvc: boolean;
  password: boolean;
  questions: boolean;
  ooba: boolean;
  expires_at: string;
  device_status: string;
  authorization_blocks?: string[];
}

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1Authentication
 */
export const createGetAuthenticationStatusRequest = (): RequestConfig<
  AuthenticationStatusResponse
> => ({
  url: '/v1/authentication',
  method: 'GET'
});

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1AuthPassword
 */
export const createLoginRequest = ({
  login,
  password,
  fingerprint
}: {
  login: string;
  password: string;
  fingerprint: string;
}): RequestConfig<LoginResponse> =>
  createFormBodyRequestConfig({
    url: '/v1/authentication/password',
    method: 'POST',
    mutating: true,
    json: {
      login,
      password,
      fingerprint
    }
  });
