import { RequestConfig } from 'packages/http-client/fetcher';
import { WithAccessToken } from '../utils';

export enum TermType {
  ACCOUNT = 'account',
  ANYTIME_ALERTS = 'alerts',
  ANYTIME_ALERTS_v2 = 'alerts_v2',
  BENEFITS = 'benefits',
  ESIGN = 'esign',
  SAVINGS = 'savings',
  OVERDRAFT = 'overdraft',
  PRIVACY_POLICY = 'privacy_policy',
  DEBIT_CARD_TRANSFER = 'debit_card_transfer',
  BIOMETRICS_LOGIN = 'biometrics_login',
  FEE_SCHEDULE = 'fee_schedule',
  EULA = 'eula',
  CRYPTO_WALLET = 'crypto_wallet',
  CRYPTO_WALLET_PRIVACY_POLICY = 'crypto_wallet_privacy_policy',
  FEE_PLAN = 'feeplan'
}
export interface TermsResponse extends WithAccessToken {
  accepted: boolean;
  terms_url: string;
  pew_url?: string;
}

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1TermsStatus
 */
export const createTermsDocumentUrlRequest = ({
  termType
}: {
  termType: TermType;
}): RequestConfig<TermsResponse> => ({
  url: `/v1/terms/${termType}`,
  method: 'GET'
});

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1TermsAccept
 */
export const createAcceptTermsUrlRequest = ({
  termType
}: {
  termType: TermType;
}): RequestConfig<TermsResponse> => ({
  url: `/v1/terms/${termType}`,
  method: 'POST',
  mutating: true
});

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1TermsDecline
 */
export const createOptOutFromAcceptTermsUrlRequest = ({
  termType
}: {
  termType: TermType;
}): RequestConfig<TermsResponse> => ({
  url: `/v1/terms/${termType}`,
  method: 'DELETE',
  mutating: true
});
