import { RequestConfig } from 'packages/http-client/fetcher';

export interface DashboardTilesData {
  id: string;
}

export const createGetDashboardTilesRequest = (): RequestConfig<
  DashboardTilesData[]
> => {
  return {
    url: `/v2/dashboardtiles`,
    method: 'GET'
  };
};
