import {
  RequestConfig,
  createFormBodyRequestConfig
} from 'packages/http-client/fetcher';
import { authBlocksRequest, skipAuthErrorValidation } from '../utils';

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1AuthCvc
 */
export const createPanCvvValidationRequest = ({
  pan,
  cvc
}: {
  pan: string;
  cvc: string;
}): RequestConfig =>
  authBlocksRequest(
    createFormBodyRequestConfig(
      skipAuthErrorValidation({
        url: '/v1/authentication/cvc',
        method: 'POST',
        mutating: true,
        json: {
          pan,
          cvc
        }
      })
    )
  );
