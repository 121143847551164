import { filterUndefinedProperties } from 'apps/acp/packages/filter-undefined-properties';
import {
  createJsonBodyRequestConfig,
  RequestConfig
} from 'packages/http-client/fetcher';

interface AuthenticateRequest {
  account_fingerprint: string | undefined;
  device_fingerprint: string | null;
}
export interface AccountFingerprintAuthResponse {
  status: 'success' | 'failed';
  access_token: string;
  purpose: string;
  card_id?: string;
  activation_block?: string;
  registration_blocks?: Array<[string]>;
  account_fingerprint?: string;
  referralCode?: string;
  transaction_type?: string;
  idology_url?: string;
  user_names?: string[] | null;
  error: string;
  app_brand?: AppBrand;
  validation_errors?: ValidationErrors[];
}

interface ValidationErrors {
  error: string;
  field: string;
}

export interface NsError extends Error {
  error: string;
  app_brand?: AppBrand;
  validation_errors?: ValidationErrors[];
}

interface AppBrand {
  name: string;
  domain: string;
  mobile_app_name: string;
  ios_app_store: string;
  android_app_store: string;
  mobile_app_id: string;
  new_product_name?: string;
  variant_id: string;
}

export enum ERRORS {
  TOKEN_EXPIRED = 'password_reset.token.expired',
  FORGOT_USERNAME_TOKEN_EXPIRED = 'username_recover.token.expired',
  ACCOUNT_REGISTERED = 'account.registered',
  BRAND_INCORRECT = 'brand.incorrect',
  RESPONSE_PROCESSING_ERROR = 'response.processing_error',
  AUTH_LOCKED = 'auth.locked',
  SECONDARY_CARD_OWNER_CANNOT_REGISTER = 'secondary_card_owner.cannot_register',
  AUTH_INVALID = 'auth.invalid'
}

export enum HANDOFF_CONSTANT {
  CARD_ACTIVATION = 'card_activation',
  REGISTRATION = 'registration',
  CIP_DOCUMENT_VALIDATION = 'cip_document_validation',
  TRANSACTION_AUTHORIZED = 'transaction_authorized',
  TRANSACTION_NOT_AUTHORIZED = 'transaction_not_authorized',
  IDOLOGY_VALIDATION = 'idology_validation'
}

export interface HandoffConstant {
  name: HANDOFF_CONSTANT;
}

export const HANDOFF_PURPOSE: HandoffConstant[] = [
  {
    name: HANDOFF_CONSTANT.CARD_ACTIVATION
  },
  {
    name: HANDOFF_CONSTANT.REGISTRATION
  },
  {
    name: HANDOFF_CONSTANT.CIP_DOCUMENT_VALIDATION
  },
  {
    name: HANDOFF_CONSTANT.TRANSACTION_AUTHORIZED
  },
  {
    name: HANDOFF_CONSTANT.TRANSACTION_NOT_AUTHORIZED
  },
  {
    name: HANDOFF_CONSTANT.IDOLOGY_VALIDATION
  }
];

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV2AuthAccountFingerprint
 */
export const createAuthenticateFingerprintRequest = ({
  account_fingerprint,
  device_fingerprint
}: AuthenticateRequest): RequestConfig<
  AccountFingerprintAuthResponse | NsError
> => {
  return createJsonBodyRequestConfig({
    url: '/v2/authentication/account-fingerprint',
    method: 'POST',
    json: filterUndefinedProperties({
      account_fingerprint,
      device_fingerprint
    })
  });
};
