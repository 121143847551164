import {
  RequestConfig,
  createJsonBodyRequestConfig
} from 'packages/http-client/fetcher';

export interface ClientErrorLog {
  log_type: 'debug' | 'info' | 'warn' | 'error';
  error_code: string;
  payload: string;
  acpVersion: string;
}

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV2Log
 */
export const createClientErrorLogRequest = ({
  log_type,
  error_code,
  payload,
  acpVersion
}: ClientErrorLog): RequestConfig =>
  createJsonBodyRequestConfig({
    url: '/v2/log',
    method: 'POST',
    json: {
      log_type: log_type,
      client_version: acpVersion,
      error_code: error_code,
      payload: payload
    }
  });

export const createEnteringOACLogActionRequest = (): RequestConfig => {
  const payload = {
    action: 'entering_oac'
  };
  return createJsonBodyRequestConfig({
    url: '/v2/log/action',
    method: 'POST',
    json: payload,
    opts: { beacon: true }
  });
};

// This request is always mutating, but sometimes it's easier/harmless to treat it as not mutating
export const createLogActionRequest = ({
  actionLog,
  mutating,
  withTimeStamp
}: {
  actionLog: string;
  mutating?: boolean;
  withTimeStamp?: boolean;
}): RequestConfig => {
  const payload = {
    action: actionLog
  };
  return createJsonBodyRequestConfig({
    url: withTimeStamp ? `/v2/log/action?ts=${Date.now()}` : `/v2/log/action`,
    method: 'POST',
    json: payload,
    mutating: mutating,
    opts: { beacon: true }
  });
};
