import {
  RequestConfig,
  createJsonBodyRequestConfig
} from 'packages/http-client/fetcher';
import { MafIds, PointsRedemptionOption } from './types';
import { useHttpQuery } from 'packages/http-client/react';

export interface LoyaltyProgramData {
  redemption_period_value: string;
  yearly_cap: string;
  monthly_cap: number;
  cashback_percentage: string;
  redeemable_balances: [
    {
      amount: number;
      redemption_period: {
        start_at: string;
        redeem_at: string;
        expires_at: string;
      };
    }
  ];
  currently_earning: {
    balance: number;
    redemption_period: {
      expires_at: string;
      redeem_at: string;
    };
  };
  redeemed_balance: number;
  conversion_rate_value: number;
}

export interface ReedeemLoyaltyProgramData {
  amount: number;
  redemption_period: {
    start_at: string;
    redeem_at: string;
    expires_at: string;
  };
}

interface CardImage {
  width: string;
  density: string;
  url_template: string;
  association?: string;
  banks?: string;
}

interface Card {
  association: string;
  cardOrderDate: string;
  id: string;
  issuer_number: string;
  obscured_pan: string;
  pan_last_4: string;
  status: string;
  activation_date?: Date;
  card_owner_id?: string;
  category?: string;
  embossedName?: string;
  expiration_date?: Date;
  images?: {
    [key: string]: CardImage;
  };
  inactivation_date?: Date;
  lost_date?: Date;
  stolen_date?: Date;
  type?: string;
  shipped_date?: string;
}

interface OverdraftFeeTxDetail {
  txPendedDate: string;
  availableBalanceWhenOdFeePended: number;
  originalTxAmount: number;
  originalTxMerchant: string;
}

export interface MerchantDetails {
  merchant_name_cleansed: string;
  address_cleansed: string;
  city_name_cleansed: string;
  country_code_cleansed: string;
  phone_number_cleansed: string;
  postal_code_cleansed: string;
  latitude: string;
  longitude: string;
}

export interface Transaction {
  id: string;
  date: string;
  amount: number;
  code?: number;
  credit: boolean;
  memo?: string;
  merchant?: string;
  merchant_details?: MerchantDetails;
  category?: string;
  card?: Card;
  running_balance?: number;
  flags: Array<'debit' | 'credit' | 'pending' | 'fee' | 'cashback'>;
  overdraft_fee_tx_detail?: OverdraftFeeTxDetail;
}

export interface TransactionResponse {
  transactions: Transaction[];
}

interface MessageAboveFocusResponse {
  id?: MafIds;
}

export interface PointsRedemptionOptionsResponse {
  options: PointsRedemptionOption[];
}

export interface LoyaltyBalanceResponse {
  available_balance: number;
  available_points: number;
  available_loyalty_cash_balance: number;
  max_loyalty_points_redeemed: boolean;
  redeemed_amount?: number;
}

export enum PointsRedemptionTypes {
  CASH = 'CASH',
  EXTERNAL = 'EXTERNAL'
}

export interface PointsLoyaltyProgram {
  name: string;
  display_name: string;
  redemption_type: PointsRedemptionTypes;
  max_points_per_month: number;
  currency_unit_singular: string;
  currency_unit_plural: string;
  currency_unit_singular_short?: string;
  currency_unit_plural_short?: string;
  redemption_period?: string;
  redemption_period_value?: string;
  expiration_period?: string;
  expiration_period_value?: string;
  conversion_rate?: number;
  conversion_rate_value?: number;
}

export interface PointsWayToEarn {
  event_code: number;
  amount: number;
  recurring: boolean;
  completed: boolean;
  conversion_rate: number;

  event_restriction?: string;
  _amount?: number;
}

export interface PointsWaysToEarnResponse {
  loyalty_program: PointsLoyaltyProgram;
  ways_to_earn: PointsWayToEarn[];
}

export interface PointsEvent {
  id: string;
  sequence: number;
  date: string;
  amount: number;
  code: number;
  category: string;
  memo: string;
  running_balance: number;
  converted_balance: number;
  transaction_amount?: number;
  credit?: boolean;
  converted_points?: number;
}

export interface PointsHistory {
  events: PointsEvent[];
  more_data_available: boolean;
}

export enum FeatureName {
  LOYALTYPOINTSELIGIBLE = 'webapi:loyalty_points_eligible',
  LOYALTYPOINTSGATEWAY = 'webapi:loyalty_points_gateway',
  ESIGN = 'webapi:esign',
  LOYALTYPOINTSTERMS = 'webapi:loyalty_points_terms',
  CIPAPPROVED = 'webapi:cip_approved'
}

export interface FeatureResponse {
  features: { [name: string]: boolean };
}

export const createGetLoyaltyProgramRequest = (): RequestConfig<
  LoyaltyProgramData
> => {
  return {
    url: `/v1/loyaltypoints/loyaltyprogram`,
    method: 'GET'
  };
};

export const createGetLoyaltyCashBackBalance = (): RequestConfig<
  LoyaltyProgramData
> => {
  return {
    url: `/v1/account/loyalty/cashback/balance`,
    method: 'GET'
  };
};

export const createReedemLoyaltyCashBackBalance = ({
  amount,
  redemption_period
}: ReedeemLoyaltyProgramData): RequestConfig<void> =>
  createJsonBodyRequestConfig({
    method: 'POST',
    url: '/v1/account/loyalty/cashback/redeem',
    json: {
      amount,
      redemption_period
    }
  });

export const createTransactionsRequest = (
  count: number,
  includePending?: boolean
): RequestConfig<TransactionResponse> => {
  return {
    method: 'GET',
    url: `/v1/transactions/debit/latest/${count}${
      includePending ? '?includePending=true' : ''
    }`
  };
};

export const getMessageAboveFocusRequest = (): RequestConfig<
  MessageAboveFocusResponse
> => ({
  method: 'GET',
  url: '/v2/messageabovefocus'
});

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1LoyaltyProgramBalance
 */
export const createLoyaltyBalanceRequest = (): RequestConfig<
  LoyaltyBalanceResponse
> => ({
  url: '/v2/loyaltypoints/balance',
  method: 'GET'
});

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1LoyaltyProgramRedemptionOptions
 */
export const getRedemptionOptions = (): RequestConfig<
  PointsRedemptionOptionsResponse
> => ({
  url: '/v1/loyaltypoints/redemption/options',
  method: 'GET'
});

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1LoyaltyProgramRedeem
 */
export const pointsRedeemRequest = (option_id: string) => {
  return createJsonBodyRequestConfig({
    method: 'POST',
    url: `/v1/loyaltypoints/redemptions/${option_id}`,
    mutating: true,
    json: {}
  });
};

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1LoyaltyWaysToEarn
 */
export const getWaysToEarn = (): RequestConfig<PointsWaysToEarnResponse> => ({
  url: '/v1/loyaltypoints/waystoearn',
  method: 'GET'
});

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1LoyaltyEventHistory
 */
export const getLoyaltyPointsEvents = (): RequestConfig<PointsHistory> => ({
  url: '/v1/loyaltypoints/events',
  method: 'GET'
});

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1LoyaltyEventHistory
 */
export const getLoyaltyPointsEventsForBrinksArmored = (): RequestConfig<
  PointsHistory
> => ({
  url: '/v2/loyaltypoints/events',
  method: 'GET'
});

// Used to de facto get the details for a certain event.
// WebAPI doesn't support getting the details for a particular event
// so I'm getting the list of events after the event before the
// desired event and limiting the result set to one.
/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV2LoyaltyEventDetails
 */

export const createLoyaltyHistoryAfterRequest = (
  after: string | number
): RequestConfig<PointsHistory> => ({
  url: `/v1/loyaltypoints/events?after_sequence=${after}&max_events=1`,
  method: 'GET'
});

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1FeaturesByName
 */
export const createGetFeatureStatusRequest = ({
  name
}: {
  name: FeatureName;
}): RequestConfig<FeatureResponse> => ({
  url: `/v1/features/${name}`,
  method: 'GET'
});

export const useFeatureRequest = (feature: FeatureName) =>
  useHttpQuery(createGetFeatureStatusRequest({ name: feature }));

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV2LoyaltyEventDetails
 */

export const createLoyaltyHistoryAfterRequestForBrinks = (
  after: string | number
): RequestConfig<PointsHistory> => ({
  url: `/v2/loyaltypoints/events?after_sequence=${after}&max_events=1`,
  method: 'GET'
});

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1LoyaltyProgram
 */
export const createLoyaltyProgramRequest = (): RequestConfig<
  PointsLoyaltyProgram
> => ({
  url: '/v1/loyaltypoints/loyaltyprogram',
  method: 'GET'
});

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1LoyaltyProgramBalance
 */
export const createLoyaltyBalanceForBrinksRequest = (): RequestConfig<
  LoyaltyBalanceResponse
> => ({
  url: '/v3/loyaltypoints/balance',
  method: 'GET'
});
