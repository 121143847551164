import { RequestConfig } from 'packages/http-client/fetcher';
import { Bank } from 'apps/acp/packages/oltp-types';
import { AuthorizationBlocks } from '../authentication';
import { ProgramType } from '../program-type';

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1BrandInformationObject
 */
export interface Brand {
  id: string;
  name: string;
  brand_name_html?: string;
  bank_full_name?: string;
  customer_program_name?: string;
  website: string;
  phone: string;
  phone_formatted: string;
  contact_address_name: string;
  contact_address_line1: string;
  contact_address_line2: string;
  fax: string;
  fax_formatted: string;
  app_name: string;
  mobile_app_short_name: string;
  android_app_url: string;
  ios_app_url: string;
  customer_support_email: string;
  product_card: string;
  product_card_html?: string;
  product_name: string;
  product_name_html?: string;
  company_name: string;
  issuer_statement_html: string;
  variant_id: string;
  premier: string;
  file_upload_email_to: string;
  default_contact_us_line1: string;
  default_contact_us_line2: string;
  atm_location_prefix: string;
}

export interface GetBrandingResponse {
  brand: Brand;
}
export interface AuthedGetBrandingResponse extends GetBrandingResponse {
  bank: Bank;
  association: string;
  type: ProgramType;
}
/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1BrandInformation
 */
export const createGetBrandingRequest = (): RequestConfig<
  GetBrandingResponse | AuthedGetBrandingResponse
> => ({
  url: '/v1/branding',
  method: 'GET'
});

export const createUnAuthedGetBrandingRequest = (): RequestConfig<
  GetBrandingResponse
> => {
  return {
    method: 'GET',
    url: '/v1/branding',
    opts: {
      // the branding api does not care about these blocks
      ignorableAuthBlocks: [
        AuthorizationBlocks.ACTIVATE_ACCOUNT,
        AuthorizationBlocks.OOBA_REQUIRED
      ]
    }
  };
};

export const isAuthedGetBrandingResponse = (
  x: unknown
): x is AuthedGetBrandingResponse => {
  return !!x && !!(x as AuthedGetBrandingResponse).type;
};

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1BrandingProductUpgrade
 */
export const createGetBrandingProductUpgradeRequest = (): RequestConfig<
  AuthedGetBrandingResponse
> => ({
  url: '/v1/branding/productupgrade',
  method: 'GET'
});
