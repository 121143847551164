import { RequestConfig } from 'packages/http-client/fetcher';
import { MafIds } from '../types';

export interface MessageAboveFocusResponse {
  id?: MafIds;
}

export const createGetMessageAboveFocusRequest = (): RequestConfig<
  MessageAboveFocusResponse
> => ({
  method: 'GET',
  url: '/v2/messageabovefocus'
});
