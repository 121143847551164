import { RequestConfig } from 'packages/http-client/fetcher';

export interface AnalyticsExternalId {
  external_id: string;
}

export const createAnalyticsIdsRequest = (): RequestConfig<
  AnalyticsExternalId
> => ({
  method: 'GET',
  url: '/v2/analytics/id'
});
