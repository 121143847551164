import { RequestConfig } from 'packages/http-client/fetcher';

export const skipAuthErrorValidation = <T extends RequestConfig>(
  requestConfig: T
): T => {
  return {
    ...requestConfig,
    opts: {
      ...requestConfig.opts,
      skipAuthErrorValidation: true
    }
  };
};

export const authBlocksRequest = <T extends RequestConfig>(
  requestConfig: T
): T => {
  return {
    ...requestConfig,
    opts: {
      ...requestConfig.opts,
      authBlocksRequest: true
    }
  };
};

export interface WithAccessToken {
  access_token: string;
}
