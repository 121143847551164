import { RequestConfig } from 'packages/http-client/fetcher';

export const createDirectOffersRequest = (
  offerId: string | undefined
): RequestConfig<void> => {
  return {
    method: 'GET',
    url: `/v2/directrewards/offers/${offerId}`
  };
};
