import {
  createFormBodyRequestConfig,
  createJsonBodyRequestConfig,
  RequestConfig
} from 'packages/http-client/fetcher';

type MethodType = 'GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export interface Notification {
  id: string;
  suppressible: boolean;
  data?: {
    body: string;
    body_key: string;
    body_with_html_tags?: string;
    body_with_html_tags_key?: string;
    body_extended?: string;
    body_extended_key?: string;
    body_disclosure?: string;
    body_disclosure_key?: string;
    headline: string;
    headline_key: string;
    cta_label: string;
    cta_label_key: string;
    suppress_label?: string;
    suppress_label_key?: string;
    cta_action: {
      type: string;
      data: string | { href: string; target: string };
      url?: string;
    };
    api_action: {
      url: string;
      method: MethodType;
    };
    type: string;
  };
}

export interface NotificationsData {
  widgets: Notification[];
}

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1NotificationWidgetsList
 */
export const createNotificationsRequest = (): RequestConfig<
  NotificationsData
> => {
  const insertion_point = 'oac_dashboard';
  const count = 25;
  return {
    url: `/v1/notifications?count=${count}&insertion_point=${insertion_point}`,
    method: 'GET'
  };
};

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1WidgetsSuppress
 */

export const createDeleteNotificationsRequest = (id: string): RequestConfig => {
  return createJsonBodyRequestConfig({
    method: 'DELETE',
    url: `/v1/notifications/${id}`,
    json: {},
    mutating: true
  });
};

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1WidgetsRespond
 *
 * response_type is by default interacted but if createDeleteNotificationsRequest is
 * called we need to call createRespondNotificationsRequest with response_type with
 * 'dismissed'
 */

export const createRespondNotificationsRequest = (
  id: string,
  response_type = 'interacted'
): RequestConfig => {
  return createFormBodyRequestConfig({
    method: 'POST',
    url: `/v1/notifications/${id}/respond`,
    json: {
      response: `${response_type}`
    },
    mutating: true
  });
};

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1WidgetsView
 */

export const createViewNotificationsRequest = (id: string): RequestConfig => {
  return createFormBodyRequestConfig({
    method: 'POST',
    url: `/v1/notifications/${id}/view`,
    json: {
      trigger: 'notification-center'
    },
    mutating: true
  });
};
