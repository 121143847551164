import {
  createFormBodyRequestConfig,
  RequestConfig
} from 'packages/http-client/fetcher';
import { filterUndefinedProperties } from 'apps/acp/packages/filter-undefined-properties';

export interface Address {
  contactName: string;
  line1: string;
  line2?: string;
  city: string;
  regionOrState: string;
  zip: string;
  isoCountryCode: string;
}

export interface DirectDeposit {
  bank: string;
  account_number: string;
  routing_number: string;
  address: Address;
}

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV1DirectDepositShow
 */
export const createGetDirectDeposit = (): RequestConfig<DirectDeposit> => ({
  method: 'GET',
  url: '/v1/directdeposit'
});

export interface SendDirectDepositEmailRequest {
  recipient_email_address: string;
  customer_message?: string;
  deposit_amount_percent?: string;
  deposit_amount_dollars?: string;
}

/**
 * https://confluence.corp.netspend.com/display/~adahiya/ChangeDoc+for+Direct+Deposit+Email+for+un-forklifted+ACP+4.0
 */
export const createSendDirectDepositEmail = ({
  recipient_email_address,
  customer_message,
  deposit_amount_percent,
  deposit_amount_dollars
}: SendDirectDepositEmailRequest): RequestConfig<void> =>
  createFormBodyRequestConfig({
    method: 'POST',
    url: '/v1/directdeposit/formEmail',
    json: filterUndefinedProperties({
      recipient_email_address,
      customer_message,
      deposit_amount_percent: deposit_amount_percent || '',
      deposit_amount_dollars: deposit_amount_dollars || ''
    }),
    mutating: true,
    opts: {
      beacon: true
    }
  });
